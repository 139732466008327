import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./NavbarContainer.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "./context/AuthContext";
const NavbarContainer = (props) => {
const {pathname}=useLocation()
  const {auth,setAuth,tasklength,setTasklength} =useContext(AuthContext)
  const navigate=useNavigate()
  const [showMenu, setShowMenu] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
const headers = {
  Authorization: auth.token,
};
const url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getTasks(); // Replace 'getUsers' with 'getTasks'
    // Rest of your code...
  }, []);
  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(null);
    navigate('/');
  };
  useEffect(() => {
    (async () => {
      try {
        const user = await 
          axios.get(`${url}api/admin/get-current-user`,{ headers })
     let userData=user.data

     setPreviewImage(userData.profileImg?userData.profileImg:"/placeholder@2x.png")
  
      } catch (error) {
       handleLogout()
      }
    })();
  },[]);

  const formatDateNew = (dateTimeString) => {
    if (!dateTimeString) {
      return ""; // Handle cases where the date-time string is empty or undefined
    }
  console.log(dateTimeString)
    const dateTime = new Date(dateTimeString);
    console.log(dateTime)
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");
    const seconds = String(dateTime.getSeconds()).padStart(2, "0");
  
    return `${month}-${day}`;
  };
  const getTasks = async () => {
    try {
      const response = await axios.get(`${url}api/todo/get`, { headers });
    
      // Set the filtered contacts in the state
      const filteredData = response.data.filter((item) => item.IsRead == false);
      const today = new Date();
      const weekFromNow = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
      
      // const filteredData = response.data.filter((item) => {
      //   return new Date(item.FollowupDate) > today &&  
      //   new Date(item.FollowupDate) < weekFromNow;
      // });
      // Set the filtered contacts in the state

const todayMonthDay = (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');

const birthdayTodos = filteredData.filter((todo) => {
  if (todo.isBirthday || todo.isAnniversary) {
    console.log(todo.FollowupDate)
    // Extract the month and day part of the FollowupDate
    const todoMonthDay = formatDateNew(todo?.FollowupDate)
console.log(todoMonthDay,"dfadaffdf",todayMonthDay)
    return todoMonthDay === todayMonthDay;
  }
  return todo
});
console.log(response.data,"SFffddfdf")
      setTasklength(birthdayTodos.length);
      console.log(response.data)
    

    } catch (error) {
      
    
    }

  };
  return (
    <div className="top-navbar">
        

     
      {/* <div className="search-group">
       <input type="text" placeholder="Search here"/>
       <img src="/search.svg" />
      </div> */}

      <div className="icon-dashboard">
        <div className="icon-dashboard-child" />
        <div className="icon-dashboard-item" />
       <Link to="/todo-list"> <img className="icon-dashboard1" alt="" src="/icon-dashboard.svg" /></Link>
      <Link to="/profile">  <img className="icon-dashboard2" alt="" src="/icon-dashboard1.svg" /></Link>
        <div className="background-group">
          <div className="background6" />
          <div className="div3">{tasklength}</div>
        </div>
      </div>

      <div className="profile-parent"     onMouseEnter={() => setShowMenu(true)}
    onMouseLeave={() => setShowMenu(false)}>
        <div className="separator" />

        <div className="profile3">
          <div className="hello-samantha">
            {/* <span>{`Hello, `}</span> */}
            <span className="samantha">{props.nameofuser}</span>
            
          </div>
        </div>
        <Link >  <div 
    className="avatar"

  >
    <img className="placeholder-icon3" src={previewImage}/>
    
    {showMenu && (
      <div  onClick={() => setShowMenu(false)} className="profile-menu">
        <Link to="/profile">My Profile</Link>
        <Link  onClick={handleLogout}>Logout</Link>  
      </div>
    )}

  </div>
        </Link>
      </div>
     
    </div>
  );
};

export default NavbarContainer;
