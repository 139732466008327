import React, { useState, useEffect, useContext, useRef } from "react";
import Select from 'react-select';
import "./admin.css"
import * as XLSX from 'xlsx';
import Modal from "react-modal";
import axios from "axios";
import { AuthContext } from "./context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Message, toaster } from "rsuite";
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate, useRouter } from "react-router-dom";

const Contact = ({role}) => {
  const [contacts, setContacts] = useState([]);
  const[parentid,setParentId]=useState()
  const navigate=useNavigate();
  const [fileName, setFileName] = useState("");
  const[parentView,setParentView]=useState(false)
  const[parentName,setParentName]=useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [users, setUsers] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [viewState, setViewState] = useState("contacts")
  const [currentPage, setCurrentPage] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);

  const fileRef = useRef(null);

  const { auth, property, setProperty, setAuth } = useContext(AuthContext);
  const headers = {
    Authorization: auth.token,
  };
  const url = process.env.REACT_APP_API_URL;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };

  }, []);


  const downloadExampleExcel = () => {
    const data = [
      ['Name', 'Email', 'Address', 'Phone'],
     
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ExampleSheet');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'example.xlsx');
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    closeModal()
  };

    const handleDeleteClick = (propertyId) => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this contact?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(propertyId),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };
  const handleDelete = async (propertyId) => {
    await axios.delete(`${url}api/contacts/delete/${propertyId}`, { headers });

    toast.success('Contact deleted successfully', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
    setContacts(contacts.filter((p) => p.id !== propertyId));
  };
  const openModal = (mode, role) => {
    setModalMode(mode);
 
    setIsOpen(true);
  };

  const closeModal = () => {
    setModalMode("");
 
    setIsOpen(false);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#000",
      border:"1px solid #fff",
    },
    overlay:{
      backgroundColor: "rgb(0 0 0 / 75%)",
    }
  };
  useEffect(() => {
    getContacts();
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/get-users`, { headers });
      setUsers(res.data);

    } catch (error) {

    }
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Handle cases where the date string is empty or undefined
    }


  

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
  
    return `${year}-${month}-${day}`;
  };
  const filteredContacts = contacts.filter((contact) => {
    const searchText = searchQuery.toLowerCase();
    return (
      contact?.firstname?.toLowerCase().includes(searchText) ||
      contact.lastname?.toLowerCase().includes(searchText) ||
      formatDate(contact.birthDate).toLowerCase().includes(searchText) ||
      contact.email?.toLowerCase().includes(searchText) ||
      (contact.address1 + ' ' + contact.address2).toLowerCase().includes(searchText) ||
      contact.city?.toLowerCase().includes(searchText) ||
      contact.provinceName?.toLowerCase().includes(searchText) ||
      (contact.realtor?.name.toLowerCase().includes(searchText)) ||
      contact.source?.toLowerCase().includes(searchText) ||
      contact.phone?.toLowerCase().includes(searchText)
    );
  });

  const getContacts = async () => {
    try {
      const response = await axios.get(`${url}api/contacts/get`, { headers });
      const contactsWithoutParentId = response.data.filter((contact) => contact.parentId === null);
      contactsWithoutParentId.map((contact) => 
console.log(typeof contact.isVendor)      
)
      const nonvendorcontacts = contactsWithoutParentId.filter((contact) => 
      
      contact.isVendor === false);
      const contactsWithoutParentIdandlead = nonvendorcontacts.filter((contact) => contact.isLead === false );
      // Set the filtered contacts in the state
      console.log(contactsWithoutParentId,"DFddffd")
      setContacts(contactsWithoutParentIdandlead);
    

    } catch (error) {
      console.log(error)
      // localStorage.removeItem('token');
      // setAuth(null);
      // navigate('/');
    }
 
  };
  const handleUpload = async () => {
    if (!selectedFile) {
      console.log("hereeeeee")
      toast.push(
        <Message type="error" closable duration={5000}>
         Please select a file
        </Message>,
        { placement: 'topEnd' }
      );
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/contacts/import`, formData, {headers});
      toast.success(' File upload successful', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
  
      closeModal()
      getContacts()
      // Do something with the response if needed
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error
    }
  };
  const ExampleFileDownloadCSV = () => {

      const csvContent = `Name,Email,Address,Phone`;
  
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'example.csv');
      document.body.appendChild(link);
  
      link.click();
  
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      closeModal()
    };
  
  const contactsPerPage = 10; // Adjust the number of contacts per page as needed

  const contactsToDisplay = filteredContacts.slice(
    (currentPage - 1) * contactsPerPage,
    currentPage * contactsPerPage
  );
// Adjust the number of contacts per page as needed
  const totalPages = Math.ceil(filteredContacts.length / contactsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const changeView=async(id,name)=>{

localStorage.setItem("parent",name)

  setParentName(name)
  // setParentId(id)
  //   setParentView(true)
   navigate(`${id}`)
    console.log(id)
    try {
        const response = await axios.get(`${url}api/contacts/get-children/${id}`, { headers });
        const contactsWithoutParentId = response.data.filter((contact) => contact.parentId === null);
  
        // Set the filtered contacts in the state
        setContacts(response.data);
      
  
      } catch (error) {
        // localStorage.removeItem('token');
        // setAuth(null);
        // navigate('/');
      }
  }
  const convert=(string)=>{
 console.log(string)
   return string
  }
  const formatPhoneNumber = (phoneNumber) => {
    return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6,10)}`;
  };
  // Rest of your component remains the same...

  return (
    <div className="add_property_btn">
          <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
           {modalMode === "add" && (
        <div className="modal-roles-add download-file">
          <button className="close-btn" onClick={closeModal}><img src="/plus.svg"/></button>
          <div>
            <input style={{visibility:"hidden",padding:"0",height:"0"}}></input>
       <button onClick={downloadExampleExcel}>Example Excel</button>
      <button onClick={ExampleFileDownloadCSV}>Example Csv</button>
      </div>
        </div>
        )}
{modalMode === "upload" && (
     <div className="modal-roles-add download-file">
     <button className="close-btn" onClick={closeModal}><img src="/plus.svg"/></button>
                 <div>
 <input style={{display:"none"}}  ref={fileRef} type="file" onChange={handleFileChange} />
 <div className="upload-text file-upload"  onClick={() => fileRef.current.click()}>
 <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="upload-icon"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clipRule="evenodd"
            />
          </svg>
          <p> {fileName}</p>
          <span>Upload File</span>
  </div>
      <button style={{width:"100%"}} onClick={handleUpload}>Upload Bulk Contacts</button>
      </div>

        </div>
        )}
    
      </Modal>
   
        <div className="inner-pages-top">
      <h3>  {parentView&&<button className="back-only-btn" 
      onClick={()=>{
        if (parentView) {
          getContacts();
          setParentView(false);
          setViewState("contacts"); // Change the view state to "contacts"
        }
      }}
      > <img src="/back.svg" /></button>} {parentView ?`${parentName} Family `:"Contacts"}</h3>
      <div className="add_user_btn">

     {parentView ? <button onClick={() =>navigate(`/contacts/add/${parentid}`)}>
        <img src="/plus.svg" />
   {`${parentName} Family Member`}</button>:
   
    <button onClick={() =>navigate("/contacts/add")}>
    <img src="/plus.svg" />
    Add Contact</button>
   }
      </div>
      <div className="search-group">

       <input type="text"
       value={searchQuery}
       onChange={(e) => setSearchQuery(e.target.value)}
       placeholder="Search here"/>
       <img src="/search.svg" />
      </div>
     <div className="add_user_btn" style={{display:"flex"}}>
              <button style={{marginLeft:"30px"}} onClick={(e) => 
              {
                e.preventDefault()
              openModal("add")
            
            }}>Sample files</button>
      <button style={{marginLeft:"30px"}} onClick={(e) => 
              {
                e.preventDefault()
              openModal("upload")
            
            }}>Upload Bulk</button>
      {/* <button onClick={downloadExampleExcel}>Example Excel</button>
      <button onClick={ExampleFileDownloadCSV}>Example Csv</button>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload Bulk</button> */}
      </div>
      </div>
    
      {/* Rest of your component remains the same... */}

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
             <th>Email Id</th>
              <th>Address</th>
              <th>Services Require</th>
            <th>Owner</th>
            <th>Active Agent</th>
        
            
              {/* <th></th>
              <th></th> */}
              <th></th>
            {role==1&&  <th></th>}

            </tr>
          </thead>
          {contacts.length>0 &&
              contactsToDisplay.map((contact) => ( <tbody>
          
                <tr key={contact.id}>
                  <td className="property-link" onClick={() => navigate("/contact/edit/"+contact.id)}>{contact.firstname}</td>
                 
                  <td>{contact.phone&&formatPhoneNumber(contact.phone)}</td>
                  <td>{contact.email}</td>
                  <td>{contact.address1}</td>
                  <td>{contact.servceRequire?.replace(/[\[\]"]/g, '')}</td>  
            <td>{contact.realtor?.name}</td>

          

            <td>{contact.activeAgent?.name}</td>
          
                  {/* <td> 
                    
                  <button className="permissions"
                    onClick={() => {changeView(Number(contact.id),contact.firstname)

                   }}> Family Members</button>
                     
          
          </td> */}
          {/* <td>  <button className="permissions"
          onClick={()=>{
            localStorage.setItem("parent",contact.firstname)
            navigate(`/contacts/property/${contact.id}`)
          }}       > Properties</button>       </td> */}
<td>
<button className="permissions"
          onClick={()=>{
         localStorage.setItem("parent",contact.firstname)
         localStorage.setItem("phone",contact.phone)
        
            navigate(`/todo-list/add/${contact.id}`)
          }}       >Create Task</button>
          </td>
               
            {  role==1&&  <td> <button className="permissions"
          onClick={()=>handleDeleteClick(contact.id)}       >Delete</button></td> }
              </tr>
          </tbody> ))}
        </table>
        {totalPages > 1 && (
  <div className="pagination">
    {Array.from({ length: totalPages }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => handlePageChange(index + 1)}
        className={currentPage === index + 1 ? 'active' : ''}
      >
        {index + 1}
      </button>
    ))}
  </div>
)}

      </div>
      { contactsToDisplay.length==0 && <p className="no-data">No data Found</p>}
    </div>
  );
};

export default Contact;
